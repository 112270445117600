import { Box } from "@mui/material";
import { TimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

export const CmtButtonTimePicker = ({
    disableFuture = false,
    value,
    setValue,
    name,
    error,
    initialFocusedDate = null,
    label,
    onTouched = null,
    open = null,
    setOpen,
    ...rest
}) => {
    if (!open) {
        return <></>;
    }

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Box md={2}>
                    <TimePicker
                        disableFuture={disableFuture}
                        initialFocusedDate={initialFocusedDate}
                        allowKeyboardControl
                        openTo={"hours"}
                        clearable
                        name="name"
                        label={label}
                        value={value || null}
                        onChange={(date) => {
                            setValue(date);
                        }}
                        autoOk
                        onOpen={() => {
                            if (!onTouched) {
                                return;
                            }

                            onTouched(name, false, false);
                        }}
                        onClose={() => {
                            setOpen(null);

                            if (!onTouched) {
                                return;
                            }
                            onTouched(name, true, false);
                        }}
                        PopperProps={{
                            anchorEl: open,
                        }}
                        ampm={false}
                        helperText={error}
                        TextFieldComponent={() => null}
                        error={Boolean(error)}
                        open={Boolean(open)}
                        renderInput={() => null}
                        {...rest}
                    />
                </Box>
            </LocalizationProvider>
        </>
    );
};
