import React from "react";
import { Box, Button, Grid, Skeleton } from "@mui/material";
import Typography from "@mui/material/Typography";
import CmtCard from "Components/CmtCard/CmtCard";
import { ProjectItemHeader } from "../CardHeader/CardHeader";
import CmtProgressBar from "Components/CmtProgressBar/CmtProgressBar";
import CmtAvatarGroup from "Components/AvatarGroup/CmtAvatarGroup";
import history from "services/History/history";
import { EDIT_PATH, PROJECT_PATH } from "Constant";
import { AvatarComponent } from "Components/AvatarComponent/AvatarComponent";

const avatars = [
    {
        title: "info",
        src: "https://via.placeholder.com/150x150",
    },
    {
        title: "info",
        src: "https://via.placeholder.com/150x150",
    },
    {
        title: "info",
        src: "https://via.placeholder.com/150x150",
    },
    {
        title: "info",
        src: "https://via.placeholder.com/150x150",
    },
];

export const ProjectListItem = ({ projectData, index }) => {
    return (
        <Grid item xs={12} md={6} xl={4}>
            <CmtCard className="fullHeight">
                <Box display="flex" flexDirection="column" height={"100%"}>
                    <ProjectItemHeader title={projectData.name} />
                    <Box flex={1} alignItems="center" display="flex" flexDirection="column">
                        <Box
                            mb={2}
                            mt={2}
                            display="flex"
                            alignItems="center"
                            flexDirection="column"
                            justifyContent="center"
                        >
                            <Box mb={{ xs: 4, xl: 6 }}>
                                <AvatarComponent
                                    size={50}
                                    src={
                                        projectData.logoFileName ||
                                        "/images/dashboard/icon-german.png"
                                    }
                                    alt="logo"
                                />
                            </Box>

                            <Typography component="div" variant="h4" mb={4}>
                                {projectData?.name}
                            </Typography>

                            <Box mb={{ xs: 5, xl: 8 }}>
                                <CmtAvatarGroup avatarSize={25} spacing items={avatars} />
                            </Box>

                            <CmtProgressBar
                                value={40}
                                label="12/45 Task completed"
                                labelPos="bottom-center"
                            />
                        </Box>
                        <Box mt="auto" width={1}>
                            <Button
                                color="primary"
                                variant="contained"
                                fullWidth
                                id={`openProject-${index}`}
                                onClick={() =>
                                    history.push(`${PROJECT_PATH}/${projectData.id}${EDIT_PATH}`)
                                }
                            >
                                Ouvrir le projet
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </CmtCard>
        </Grid>
    );
};

export const ProjectSkeletonListItem = () => {
    return (
        <Grid item xs={12} md={6} xl={4}>
            <CmtCard className="fullHeight">
                <Box display="flex" flexDirection="column" height="100%">
                    <Box pt={6} pl={6}>
                        <Skeleton variant="text" width="40%" />
                    </Box>
                    <Box flex={1} alignItems="center" display="flex" flexDirection="column">
                        <Box
                            mb={2}
                            mt={10}
                            display="flex"
                            width="100%"
                            alignItems="center"
                            flexDirection="column"
                            justifyContent="center"
                        >
                            <Box mb={{ xs: 4, xl: 6 }}>
                                <Skeleton variant="circular" width={50} height={50} />
                            </Box>

                            <Box mb={{ xs: 2, xl: 3 }} width="50%">
                                <Skeleton variant="text" width="100%" />
                            </Box>

                            <Box mt={1} display="flex">
                                <Skeleton variant="circular" width={30} height={30} />
                                <Skeleton variant="circular" width={30} height={30} />
                                <Skeleton variant="circular" width={30} height={30} />
                                <Skeleton variant="circular" width={30} height={30} />
                            </Box>

                            <Box
                                mt={8}
                                mb={9}
                                display="flex"
                                justifyContent="center"
                                width="100%"
                                alignItems="center"
                            >
                                <Skeleton
                                    variant="rectangular"
                                    width="30%"
                                    height={4}
                                    sx={{ mr: 2 }}
                                />
                                <Skeleton variant="rectangular" height={10} width="3%" />
                            </Box>
                        </Box>
                        <Box mt="auto" width={1}>
                            <Skeleton
                                variant="rectangular"
                                width="100%"
                                height={30}
                                sx={{ mr: 2 }}
                            />
                        </Box>
                    </Box>
                </Box>
            </CmtCard>
        </Grid>
    );
};
