import { Box, Grid } from "@mui/material";
import CmtGridContainer from "Components/CmtGridContainer/CmtGridContainer";
import { ProjectHeader } from "./ProjectHeader/ProjectHeader";
import { useDispatch } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import auth from "services/Api/auth";
import { notConnectedAction } from "redux/actions/authActions";
import project from "services/Api/project";
import { NotificationManager } from "react-notifications";
import { PROJECT_PATH, PROJECT_TAB, REDIRECTION_TIME, SHARE_TAB, SPECTACLE_TAB } from "Constant";
import history from "services/History/history";
import { ProjectInfosCard } from "./ProjectTab/ProjectInfos/ProjectInfosCard";
import { ProjectDescriptionCard } from "./ProjectTab/ProjectDescription/ProjectDescriptionCard";
import { ProfileContainer } from "Components/CmtProfileContainer/sc.ProfileContainer";
import { CmtTabs } from "Components/CmtTabs/CmtTabs";
import { ShareTab } from "./ShareTab/ShareTab";
import { TodoList } from "./TodoList/TodoList";
import { ProjectLocation } from "./ProjectTab/ProjectLocation/ProjectLocation";
import { SpectacleTab } from "./SpectacleTab/SpectacleTab";
import { DisplayProjectCalendar } from "./ProjectTab/ProjectInfos/DisplayProjectCalendar";

export const ProjectDetail = ({ projectId, profile }) => {
    const dispatch = useDispatch();

    const [projectData, setProjectData] = useState(null);

    const [tabValue, setTabValue] = useState(PROJECT_TAB);

    const fetchData = useCallback(async () => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        const result = await project.getOneProject(projectId);

        if (result?.result) {
            setProjectData(result.data);
        } else {
            NotificationManager.error(
                auth?.error?.message || "Une Erreur s'est produite",
                "Erreur",
                REDIRECTION_TIME
            );

            history.push(PROJECT_PATH);
        }
    }, [dispatch, projectId]);

    useEffect(() => {
        fetchData();
    }, [dispatch, fetchData]);

    const handleUpdateProject = async (values) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        let data = { ...projectData, ...values };

        data.entityId = projectId;

        data.projectType = values.projectType || data.projectType.id;

        if ("coverFile" in values) {
            data.coverFile = values.coverFile;
        }

        if ("logoFile" in values) {
            data.logoFile = values.logoFile;
        }

        const result = project.editProject(data).then((result) => {
            if (result?.result) {
                setProjectData(result?.data);
            }

            if (!result?.result) {
                NotificationManager.error(
                    result?.error?.message || "Une Erreur s'est produite",
                    "Erreur",
                    REDIRECTION_TIME
                );
            }

            return result;
        });

        return result;
    };

    const handleInviteMember = async (data) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        const result = project
            .inviteMember({ ...data, projectId: projectData?.id })
            .then((result) => {
                if (result.result) {
                    let newData = projectData;

                    newData.projectLocalizedEntities.push(result?.data);

                    setProjectData({ ...newData });

                    NotificationManager.success(
                        "Votre invitation à bien été envoyé",
                        "Succès",
                        REDIRECTION_TIME
                    );
                } else {
                    NotificationManager.error(
                        result?.error?.message || "Une Erreur s'est produite",
                        "Erreur",
                        REDIRECTION_TIME
                    );
                }

                return result;
            });

        return result;
    };

    const handleDeleteInvite = async (id) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        project
            .deleteInvitation({
                entityId: id,
            })
            .then((result) => {
                if (result?.result) {
                    let list = projectData?.projectLocalizedEntities.filter(
                        (elem) => elem.id !== id
                    );

                    setProjectData({ ...projectData, projectLocalizedEntities: list });
                } else {
                    NotificationManager.error(
                        result?.error?.message || "Une Erreur s'est produite",
                        "Erreur",
                        REDIRECTION_TIME
                    );
                }
            });
    };

    const handleUpdateMember = async (data) => {
        const check = await auth.checkIsAuth();

        if (!check) {
            dispatch(notConnectedAction());

            return;
        }

        const result = project.updateMember(data).then((result) => {
            if (result.result) {
                let newData = projectData;

                newData.projectLocalizedEntities[
                    newData.projectLocalizedEntities.findIndex(
                        (elem) => (elem.id = data.invitationId)
                    )
                ] = result?.data;

                setProjectData({ ...newData });

                NotificationManager.success(
                    "Le status de l'utilisateur à été mis à jour",
                    "Succès",
                    REDIRECTION_TIME
                );
            } else {
                NotificationManager.error(
                    result?.error?.message || "Une Erreur s'est produite",
                    "Erreur",
                    REDIRECTION_TIME
                );
            }

            return result;
        });

        return result;
    };

    const handleMockUpUpdateProject = (values) => {
        console.log(values);
        setProjectData({ ...projectData, ...values });
    };

    return (
        <ProfileContainer>
            <ProjectHeader project={projectData} updateProject={handleUpdateProject} />
            <Box display="flex" justifyContent="flex-end" mb={5}>
                <CmtTabs
                    value={tabValue}
                    setValue={setTabValue}
                    list={[
                        { value: PROJECT_TAB, label: "Projet" },
                        { value: SPECTACLE_TAB, label: "Spectacle" },
                        { value: SHARE_TAB, label: "Partage" },
                    ]}
                />
            </Box>

            {tabValue === PROJECT_TAB && (
                <CmtGridContainer spacing={0}>
                    <Grid item xs={12} lg={4}>
                        <Box p={2}>
                            <TodoList
                                projectData={projectData}
                                updateProject={handleUpdateProject}
                            />
                        </Box>
                        <Box p={2}>
                            <ProjectLocation
                                projectData={projectData}
                                updateProject={handleUpdateProject}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={8}>
                        <Box p={2}>
                            <ProjectInfosCard
                                projectData={projectData}
                                updateProject={handleUpdateProject}
                            />
                        </Box>

                        {projectId === "36" && (
                            <Box p={2}>
                                <DisplayProjectCalendar
                                    projectData={projectData}
                                    updateProject={handleUpdateProject}
                                />
                            </Box>
                        )}

                        <Box p={2}>
                            <ProjectDescriptionCard
                                projectData={projectData}
                                updateProject={handleUpdateProject}
                            />
                        </Box>
                    </Grid>
                </CmtGridContainer>
            )}

            {tabValue === SPECTACLE_TAB && (
                <SpectacleTab projectData={projectData} updateProject={handleMockUpUpdateProject} />
            )}

            {tabValue === SHARE_TAB && (
                <CmtGridContainer spacing={0}>
                    <ShareTab
                        projectData={projectData}
                        profile={profile}
                        disconnect={() => dispatch(notConnectedAction())}
                        handleInviteMember={handleInviteMember}
                        handleDeleteInvite={handleDeleteInvite}
                        handleUpdateInvite={handleUpdateMember}
                    />
                </CmtGridContainer>
            )}
        </ProfileContainer>
    );
};
