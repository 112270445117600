import { Box, Button, Grid, Typography } from "@mui/material";
import CmtForm from "Components/CmtForm/CmtForm";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { createProjectAction } from "redux/actions/projectActions";
import { useEffect, useState } from "react";
import CmtGridContainer from "Components/CmtGridContainer/CmtGridContainer";
import { PROJECT_PATH, REDIRECTION_TIME, UNOTHAURIZED_PATH } from "Constant";
import { NotificationManager } from "react-notifications";
import history from "services/History/history";
import auth from "services/Api/auth";
import { notConnectedAction } from "redux/actions/authActions";
import project from "services/Api/project";
import moment from "moment";
import { CreateProjectInfos } from "./CreateProjectInfos";
import { CreateProjectEntity } from "./CreateProjectEntity";
import { CreateProjectDescription } from "./CreateProjectDescription";
import { CreateProjectLocation } from "./CreateProjectLocation";

export const CreateProjectApp = ({ profile }) => {
    const dispatch = useDispatch();

    const name = new URL(window.location).searchParams.get("nom");

    const listEntity = profile?.profile?.compagnyUserActives?.filter(
        (elem) => elem.accessRights === 0 && elem.userAccepted && elem.adminAccepted
    );

    const entityNumber = (profile?.profile?.intermittent ? 1 : 0) + listEntity.length;
    const [projectType, setProjectType] = useState(null);

    useEffect(() => {
        if (entityNumber === 0) {
            history.push(`${PROJECT_PATH}${UNOTHAURIZED_PATH}`);
        }
    }, [entityNumber]);

    useEffect(() => {
        const fetchData = async () => {
            const check = await auth.checkIsAuth();

            if (!check) {
                dispatch(notConnectedAction());

                return;
            }

            const result = await project.getProjectType();

            if (result?.result) {
                setProjectType(result?.data);
            } else {
                NotificationManager.error(
                    result?.error?.message || "Une Erreur s'est produite",
                    "Erreur",
                    REDIRECTION_TIME
                );
            }
        };

        fetchData();
    }, [dispatch]);

    const checkError = (values) => {
        let error = {};

        if (!values.name) {
            error.name = "Veuillez renseigner le nom de votre projet";
        }

        if (!values.projectType) {
            error.projectType = "Veuillez indiquer le type de votre projet";
        }

        if (!values.description.replace(/<[^>]+>/g, "").replace(/&nbsp/g, "")) {
            error.description = "Veuillez ajouter une description à votre projet";
        }

        if (!values.creatorEntity && entityNumber > 1) {
            error.creatorEntity = "Veuillez renseigner en qualité de quoi vous créez votre projet";
        }

        if (
            values.endDate &&
            values.startDate &&
            moment(values.startDate).isAfter(values.endDate)
        ) {
            error.startDate = "La date de début ne peux pas être supérieure à la date de fin";
        }

        if (
            values.startDate &&
            values.endDate &&
            moment(values.endDate).isBefore(values.startDate)
        ) {
            error.endDate = "La date de fin ne peux pas être inférieure à la date de début";
        }

        return error;
    };

    const handleSubmit = async (values) => {
        let entityId = 0;

        if (values.creatorEntity) {
            entityId = values?.creatorEntity;
        } else {
            if (entityNumber > 1) {
                entityId = values.creatorEntity;
            } else {
                if (profile?.profile?.intermittent) {
                    entityId = profile?.profile?.intermittent?.id;
                } else if (profile?.profile?.compagnyUserActives?.length > 0) {
                    entityId = profile?.profile?.compagnyUserActives[0]?.compagny?.id;
                } else {
                    return;
                }
            }
        }

        console.log(entityId);
        const result = await dispatch(
            createProjectAction({
                entityId: entityId,
                name: values?.name,
                description: values?.description,
                projectType: values?.projectType,
                startDate: values?.startDate || "",
                endDate: values?.endDate || "",
                performancesNb: values?.performancesNb || "",
                fullAddress: values?.fullAddress || "",
                lng: values?.lng || "",
                lat: values?.lat || "",
            })
        );

        if (result.result) {
            history.push(PROJECT_PATH);
        } else {
            NotificationManager.error(
                result?.error?.message || "Une Erreur s'est produite",
                "Erreur",
                REDIRECTION_TIME
            );
        }
    };

    return (
        <Box
            width="100%"
            height="100%"
            display="flex"
            flexDirection="column"
            p={5}
            position="relative"
            overflow="auto"
        >
            <Typography component="p" variant="h1" pl={2} mb={2}>
                Créer un projet
            </Typography>
            <Formik
                initialValues={{
                    name: name || "",
                    description: "",
                    projectType: "",
                    creatorEntity: "",
                    startDate: "",
                    endDate: "",
                    performancesNb: "",
                    fullAddress: "",
                    lng: "",
                    lat: "",
                }}
                validate={(values) => checkError(values)}
                onSubmit={async (values, { setSubmitting }) => {
                    setSubmitting(true);

                    handleSubmit(values);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    setFieldValue,
                    setFieldTouched,
                    handleSubmit,
                    handleBlur,
                    isSubmitting,
                }) => (
                    <CmtForm onSubmit={handleSubmit} className="fullWidth">
                        <CmtGridContainer spacing={0}>
                            <Grid item xs={12} lg={4}>
                                <CreateProjectEntity
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    profile={profile}
                                    listEntity={listEntity}
                                />

                                <CreateProjectLocation
                                    values={values}
                                    setFieldValue={setFieldValue}
                                />
                            </Grid>
                            <Grid item xs={12} lg={8}>
                                <CreateProjectInfos
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    values={values}
                                    setFieldTouched={setFieldTouched}
                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                    projectType={projectType}
                                    touched={touched}
                                />
                                <CreateProjectDescription
                                    setFieldTouched={setFieldTouched}
                                    setFieldValue={setFieldValue}
                                    values={values}
                                    touched={touched}
                                    errors={errors}
                                />
                            </Grid>

                            <Grid
                                item
                                sm={12}
                                display="flex"
                                alignItems="center"
                                pr={2}
                                justifyContent="flex-end"
                            >
                                <Button
                                    id="loginButton"
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={isSubmitting}
                                >
                                    <Typography>Créer</Typography>
                                </Button>
                            </Grid>
                        </CmtGridContainer>
                    </CmtForm>
                )}
            </Formik>
        </Box>
    );
};
