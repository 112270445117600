import { useTheme } from "@emotion/react";
import { CardContent, IconButton, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { BigCalendar } from "Apps/Planning/DisplayPlanning/sc.DisplayPlanning";
import CmtCard from "Components/CmtCard/CmtCard";
import { TileTitle } from "Components/CmtProfileContainer/sc.ProfileContainer";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { momentLocalizer } from "react-big-calendar";
import CircleIcon from "@mui/icons-material/Circle";
import { CmtPopover } from "Components/CmtPopover/CmtPopover";
import linkify from "services/Linkify/linkify";
import CloseIcon from "@mui/icons-material/Close";

const localizer = momentLocalizer(moment);

const MOCK_UP = [
    {
        id: 18,
        title: "Réunion d'initialisation du projet",
        description:
            "<p>Introduction au projet, présentation des objectifs, et répartition des responsabilités.</p>",
        startDate: "2025-01-08T00:00:00+01:00",
        endDate: "2025-01-08T00:00:00+01:00",
        color: "#607d8b",
        calendar: { id: 15, color: "#2196F3" },
    },

    {
        id: 19,
        title: "Session technique : Tests des capteurs et équipements",
        description:
            "<p>Vérification des capteurs de mouvement et des systèmes de projection dans un environnement contrôlé.</p>",
        startDate: "2025-01-15T00:00:00+01:00",
        endDate: "2025-01-15T00:00:00+01:00",
        color: "#607d8b",
        calendar: { id: 15, color: "#2196F3" },
    },

    {
        id: 20,
        title: "Validation des concepts vidéo et interactifs",
        description:
            "<p>Discussion sur les concepts artistiques et intégration des idées pour les œuvres finales.</p>",
        startDate: "2025-01-20T00:00:00+01:00",
        endDate: "2025-01-20T00:00:00+01:00",
        color: "#607d8b",
        calendar: { id: 15, color: "#2196F3" },
    },

    {
        id: 21,
        title: "Préparation du lieu d'exposition",
        description:
            "<p>Inspection du site, évaluation des contraintes techniques et logistiques, établissement du plan d’installation.</p>",
        startDate: "2025-01-25T00:00:00+01:00",
        endDate: "2025-01-26T00:00:00+01:00",
        color: "#607d8b",
        calendar: { id: 15, color: "#2196F3" },
    },
];

export const DisplayProjectCalendar = () => {
    const [actualDate, setActualDate] = useState(moment());
    const [openDetail, setOpenDetail] = useState(null);
    const theme = useTheme();
    const mdBreakPoint = useMediaQuery(theme?.breakpoints.down("md"));

    const setEventPropGetter = (event) => {
        let style = {};

        style = {
            backgroundColor: event.color,
            borderLeft: `10px solid ${event?.calendar?.color}`,
        };

        return {
            style: style,
        };
    };

    const planningData = useMemo(() => {
        return { eventList: MOCK_UP };
    }, []);

    return (
        <CmtCard>
            <TileTitle>Calendrier</TileTitle>
            <CardContent sx={{ height: 800 }}>
                <BigCalendar
                    localizer={localizer}
                    selectable={true}
                    onNavigate={(date, view) => {
                        setActualDate(date);
                    }}
                    step={60}
                    defaultDate={new Date()}
                    messages={{
                        today: "Aujourd'hui",
                        previous: "Précédente",
                        next: "Suivante",
                        month: "Mois",
                        day: "Jour",
                        week: "Semaine",
                    }}
                    min={new Date(0, 0, 0, 8, 0, 0)}
                    max={new Date(0, 0, 0, 22, 0, 0)}
                    events={
                        planningData?.eventList?.map((item) => {
                            const startHour = new Date(
                                moment(
                                    `${moment(item.startDate).format("YYYY-MM-DD")}${
                                        item.startHour
                                            ? moment(item.startHour).format(" HH:mm")
                                            : ""
                                    }`
                                )
                            );
                            const endHour = new Date(
                                moment(
                                    `${moment(item.endDate).format("YYYY-MM-DD")}${
                                        item.endHour ? moment(item.endHour).format(" HH:mm") : ""
                                    }`
                                )
                            );

                            const allDay = item?.startHour ? false : true;

                            return {
                                ...item,
                                title: (
                                    <Box width="100%">
                                        <Typography component="p">{item?.title}</Typography>
                                        {item?.projectsShared?.length > 0 && (
                                            <Box display="flex" alignItems="center">
                                                <CircleIcon
                                                    sx={{ ml: "auto", mr: 2 }}
                                                    fontSize={"10px"}
                                                />
                                            </Box>
                                        )}
                                    </Box>
                                ),
                                start: startHour,
                                end: endHour,
                                allDay: allDay,
                            };
                        }) || []
                    }
                    eventPropGetter={setEventPropGetter}
                    onSelectEvent={(item, event) =>
                        setOpenDetail({ item: item, anchorEl: event.currentTarget })
                    }
                    view={mdBreakPoint ? "day" : planningData?.filters?.mode}
                    views={mdBreakPoint ? ["day"] : ["day", "week", "month"]}
                />
            </CardContent>

            <CmtPopover
                open={Boolean(openDetail)}
                anchorEl={openDetail?.anchorEl}
                onClose={() => setOpenDetail(null)}
            >
                <Box pl={5} pr={1} pt={1} minWidth={300}>
                    <Box display="flex" justifyContent="flex-end">
                        <IconButton
                            size="small"
                            color="primary"
                            onClick={() => setOpenDetail(null)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    <Box pt={1}>
                        <Typography component="p" variant="subtitle1" fontSize={20}>
                            {openDetail?.item?.title}
                        </Typography>
                        <Typography component="p" fontSize={12}>
                            {openDetail?.item?.startDate === openDetail?.item?.endDate
                                ? `Le ${moment(openDetail?.item?.startDate).format("DD MMMM YYYY")}`
                                : `Du ${moment(openDetail?.item?.startDate).format(
                                      "DD MMMM YYYY"
                                  )} au ${moment(openDetail?.item?.endDate).format(
                                      "DD MMMM YYYY"
                                  )}`}
                        </Typography>

                        <Typography component="p" fontSize={12} mb={3}>
                            {openDetail?.item?.startHour &&
                                `De ${moment(openDetail?.item?.startHour).format(
                                    "HH:mm"
                                )} a ${moment(openDetail?.item?.endHour).format("HH:mm")}`}
                        </Typography>

                        {openDetail?.item?.reminder && (
                            <>
                                <Typography component="p" fontSize={12} pt={5} fontWeight="bold">
                                    Rappel
                                </Typography>
                                <Typography component="p" fontSize={12} mb={3}>
                                    {moment(openDetail?.item?.reminder).format(
                                        "DD MMMM YYYY  HH:mm"
                                    )}
                                </Typography>
                            </>
                        )}

                        {openDetail?.item?.fullAddress && (
                            <>
                                <Typography component="p" fontSize={12} pt={5}>
                                    Lieu
                                </Typography>
                                <Typography component="p" fontSize={12}>
                                    {openDetail?.item?.fullAddress}
                                </Typography>
                            </>
                        )}

                        {openDetail?.item?.description && (
                            <Box
                                component="p"
                                dangerouslySetInnerHTML={{
                                    __html: linkify(
                                        openDetail?.item?.description?.replace(
                                            /(?:\r\n|\r|\n)/g,
                                            "<br />"
                                        )
                                    ),
                                }}
                            />
                        )}
                    </Box>
                </Box>
            </CmtPopover>
        </CmtCard>
    );
};
